import React from 'react';
import { Link } from 'gatsby';
import programs from '../utils/programs.json';

const ProgramsList = (props) => {
  const data = programs.filter((x) => x.school === props.name);

  return (
    <section className='course-one course-page'>
      <div className='container'>
        <div className='row'>
          {data.map((course, idx) => (
            <div key={idx} className='col-lg-4'>
              <div className='course-one__single'>
                <div className='course-one__content'>
                  <h2 className='course-one__title'>
                    <Link to={course.link}>{course.name}</Link>
                  </h2>
                  {course.school !== 'School of Community Health' && (
                    <div className='course-one__stars'>
                      <span>{course.school}</span>
                    </div>
                  )}
                  <div className='course-one__meta'>
                    <i className='far fa-clock'></i> {course.duration}
                  </div>
                  <Link to={course.link} className='course-one__link'>
                    Register
                  </Link>
                </div>
              </div>
            </div>
          ))}          
        </div>        
      </div>
    </section>
  );
};

export default ProgramsList;
