import React from 'react';
import Layout from '../../components/Layout';
import NavThree from '../../components/NavThree';
import PageHeader from '../../components/SchoolHeader';
import Footer from '../../components/Footer';
import ProgramsList from '../../components/ProgramsList';

const Anaesthetic = () => {
  return (
    <Layout pageTitle='Anaesthetic Technicians | LUTH'>
      <NavThree />
      <PageHeader title='Anaesthetic Technicians' />
      <ProgramsList name='Anaesthetic Technicians' />
      <Footer />
    </Layout>
  );
};

export default Anaesthetic;
